<script>
    import { mapState } from 'pinia'
    import { useCurrentCompanyViewed } from '@store/currentCompanyViewed.js'
    import Callout from '@ui/Callout.vue'
    import { Headset } from '@libs/MDM/core/Headset.js'
    export default {
        components: { Callout },
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },
        computed: {
            ...mapState(useCurrentCompanyViewed, ['company']),
            inputs() {
                return []
            },
        },
        data() {
            return {
                element: {
                    former_id: this.headset.user?.id || null,
                },
            }
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $tc('headsets.actions.assign_user._name', 2) }}
        </template>
        <ra-form
            :element="element"
            :inputs="inputs"
            lang="fr"
        >
            <template #end>
                <ra-user-selector
                    v-model="element.former_id"
                    :company_id="company.id"
                    :label="$t('learners.affect_former.label')"
                    class="mb-4"
                    hide-details
                />
                <callout bg="primary-5-alpha">{{
                    $t('headsets.actions.assign_user.callout')
                }}</callout>
            </template>
        </ra-form>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
